import { useEffect, useState } from 'react'

import LoginContext from './LoginContext.js'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { logout as apiLogout, getCurrentUser as apiGetCurrentUser, setUnauthorizedCallback } from './api.js'

import LoginPage from './pages/LoginPage.js';
import ReportListPage from './pages/ReportListPage.js';
import ProfilePage from './pages/ProfilePage.js';
import CreateReportPage from './pages/CreateReportPage.js';
import ReportViewPage from './pages/ReportViewPage.js';
import WixLoginRedirect from './pages/WixLoginRedirect.js';

import './App.css';

function App() {

  const [loggedInUser, setLoggedInUser] = useState('')
  const logout = () => {
    const clearUser = () => setLoggedInUser(null)
    apiLogout().then(clearUser, clearUser)
  }
  const refreshUser = () => {
    apiGetCurrentUser().then(setLoggedInUser).catch((err)=>{
      console.log('Refreshing user had error', err)
    })
  }
  
  useEffect(() => {
    setUnauthorizedCallback(refreshUser)
    refreshUser()
  }, [])

  if (!loggedInUser) return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="wix-login/:wixSession" element={<WixLoginRedirect setLoggedInUser={setLoggedInUser}/>}/>
          <Route index element={<LoginPage setLoggedInUser={setLoggedInUser}/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
  
  //

  return (
    <LoginContext.Provider value={{ user: loggedInUser, logout, refreshUser}}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="reports" element={<ReportListPage/>} />
            <Route path="report/:reportId" element={<ReportViewPage />} />
            <Route path="createReport" element= {<CreateReportPage />} />
            <Route path="profile" element={<ProfilePage/>} />
            <Route path="wix-login/:wixSession" element={<Navigate to="/" replace={true} />} />
            <Route index element={<ReportListPage/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LoginContext.Provider>
  ) 
}

export default App;
