import { useEffect, useState } from 'react' 
import { useNavigate, useParams } from "react-router-dom"
import { createClient, OAuthStrategy } from "@wix/sdk"
import { loginWithOAuth } from "../api"

export default function WixLoginRedirect({ setLoggedInUser }) {

    const { wixSession } = useParams()
    const navigate = useNavigate()

    const [error, setError] = useState() 

    useEffect(() => {
        const wixClient = createClient({
            modules: { },
            auth: OAuthStrategy({
                clientId: '55f3a29d-d888-4ce1-b542-ee23863b6b43'
            })
        })
        wixClient.auth.getMemberTokensForDirectLogin(wixSession)
            .then(loginWithOAuth)
            .then(setLoggedInUser)
            .catch((err) => {
                setError("Unable to login, user not authorized for report portal use")
                console.log(err)
            })
    },[wixSession, setLoggedInUser, navigate])

    return (
        <div className="container-fluid text-center">
            <div className="row">
                <div className="vh-25"></div>
            </div>
            <div className="row align-items-center">
                <div className="col-10 col-sm-8 col-md-6 col-lg-6 mx-auto">
                    <img className="img-fluid" src='/images/AERAS.png' alt='AERAS'/>
                </div>
            </div>
            <div className="row mt-3 align-items-center">
                <div className="col">{(error) ? error : "Logging in..."}</div>
            </div>
        </div>
    )
}