import { useContext, useEffect, useState } from "react";
import Page from "./Page";
import LoginContext from "../LoginContext";
import UserView from "../components/UserView";
import { useNavigate } from "react-router-dom";
import { createNewReport, validateQuarterSection } from "../api";
import { parseQuarter } from "../ats";

export default function CreateReportPage() {
    const {user} = useContext(LoginContext)
    const navigate = useNavigate()

    const [reportName, setReportName] = useState('')

    const [quarterSections, setQuarterSections] = useState('')
    const [quarterSectionValidationError, setQuarterSectionValidationError] = useState('')
    const [quarterSectionValid, setQuarterSectionValid] = useState(false)
    const [quarterSectionValidating, setQuarterSectionValidating] = useState(false)

    const [generationError, setGenerationError] = useState('')

    const startCreateReport = () => {
        createNewReport({
            projectName: reportName,
            quarterSections: quarterSections,
        })
        .then((newReport) => {
            navigate(`/report/${newReport.id}`)
        })
        .catch((error) => {
            setGenerationError(error.message)
        })
    }

    const updateValue = (setter) => {
        return (event) => { setter(event.target.value) }
    }

    const generateValid = reportName.length > 2 && quarterSectionValid

    useEffect(() => {
        setQuarterSectionValidationError('')
        setQuarterSectionValid(false)
        if (quarterSections.length > 15) {
            try {
                parseQuarter(quarterSections) // will explode if format is bad
                setQuarterSectionValidating(true)
                validateQuarterSection(quarterSections).then(() => {
                    setQuarterSectionValid(true)
                    setQuarterSectionValidating(false)
                })
                .catch((error)=> {
                    setQuarterSectionValidationError(error.message)
                    setQuarterSectionValidating(false)
                })
            }
            catch (error) {
                setQuarterSectionValidationError(error.message)
            }
        }
        else {
            setQuarterSectionValidationError(false)
        }
    }, [quarterSections])

    return (
        <Page title="Create Report">
            <div className="row pt-3">
                <div className="col-12 col-md-6 mb-2">
                    <div className="">
                        <label className="h6" htmlFor="reportName">Report Name</label>
                        <input type="text"  className="form-control" name="reportName" id="reportName" 
                            value={reportName} onChange={updateValue(setReportName)}
                        />
                    </div>
                    <div className="mt-2">
                        {/* <button className="btn btn-secondary mb-1 float-end" ><i className="bi-map pe-2"></i>Map View</button> */}
                        <label className="h6" htmlFor="quarterSections">Quarter Section</label>
                        <input className="form-control" name="quarterSections" id="quarterSections" 
                            value={quarterSections} onChange={updateValue(setQuarterSections)}
                        />
                        { quarterSectionValidating && (
                            <div className="form-text text-end">Validating... <div className="ms-1 spinner-border spinner-border-sm"></div></div>
                        )}
                        { quarterSectionValidationError && (          
                            <div className="form-text text-danger">{ quarterSectionValidationError }</div>
                        )}
                        { !quarterSectionValid && !quarterSectionValidationError && !quarterSectionValidating && (
                            <div className="form-text">QQ-SS-TTT-RR-WmM (example SW-21-024-01-W5M)</div>
                        )}
                        { quarterSectionValid && (
                            <div className="form-text text-end text-success">Valid!</div>
                        )}

                    </div>
                </div>
                <div className="col">
                    <h6 className="mb-0">Prepare for</h6>
                    <UserView user={user}/>
                </div>
                { generationError ? <div className="col-12 text-danger">{generationError}</div> : null}
                <div className="col-12">
                    <button className="btn btn-primary" disabled={!generateValid} onClick={() => startCreateReport()}>Generate</button>
                    <button className="btn btn-tertiary ms-2" onClick={() => navigate('/reports')}>Cancel</button>
                </div>
            </div>
        </Page>
    )

}